var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})
$(document).ready(function() {
     

    

    let branches = [];
    

    $('.branchen-btn').on('click', function(){
        if (!branches.includes($(this).attr('id'))) {
            branches.push($(this).attr('id'));
            $(this).addClass('btn-secondary');
            $(this).removeClass('btn-outline-secondary');

        } else {
            branches = branches.filter((branche)=> branche!= $(this).attr('id'));
            $(this).removeClass('btn-secondary');
            $(this).addClass('btn-outline-secondary');
            


        };

    })

    $('.potential-submit').on('click', function(){
        if(!gid.length && !branches.length){
            $('.potential-alert').css('display', 'block');

        }else{
            $('.potential-spinner').css('display', 'inline-block');
            $.ajax({
            "type": "POST",
            "url": "/analyze", 
            "dataType": "json",
            "data": { "bg": branches, "gid": gid },
            "headers": { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content") },
            "success": function(response) {
                tot= new Intl.NumberFormat('de-DE').format(response['total']);
                count = new Intl.NumberFormat('de-DE').format(response['count']);
                salary = new Intl.NumberFormat('de-DE').format(response['salary']);
                $('#jobs').text(tot)
                $('#companies').text(count);
                $('#salary').text(salary + ",- EUR");
                //$("html, body").animate({ scrollTop: $(window).scrollTop() + 600 });
                $("html, body").animate({ scrollTop: $('#potential-result').offset().top }, 1000);
                $('.potential-alert').css('display', 'none');
                $('.potential-spinner').css('display', 'none');

                }
            })

        }
    })



});

